import React, { useEffect, useState } from 'react';

const Sizzle = () => {

    const [videoSrc, setVideoSrc] = useState('');

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    setVideoSrc(isMobile ? '/videos/guava-sizzle-home-2-mobile.mp4' : '/videos/guava-sizzle-home-2-desktop.mp4');
  }, []);

    return (
        <div className="videoContainer">
            <video src={videoSrc} autoPlay muted loop type="video/mp4"></video>
        </div>
    );
};

export default Sizzle;