import React from 'react';

const Headlines = () => {

    return (
        <div className='headlines m-0 p-0'>
            <div className='p-0 m-0'>
                <p className='p-0 m-0 h1 has-text-weight-bold has-text-white'>Why restart when you can make everything we have today much better? </p>
            </div>
            <div className='p-0 mt-5'>
                <button>Start today</button>
            </div>
        </div>
        );
    };
    
export default Headlines;