import React, { useState } from 'react';

const Modal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <span>
        {/* Icon trigger */}
        <span className='ml-3'>
            <a onClick={openModal} aria-label="Open modal">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 9V14M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19ZM10.0498 6V6.1L9.9502 6.1002V6H10.0498Z" stroke="#EA9292" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </a>
        </span>

        {/* Modal */}
        <div className={`modal ${isModalOpen ? 'is-active' : ''}`}>
            <div className="modalBackground" onClick={closeModal}></div>
            
            <div className="modalCard border1">
                <section className="modalCardBody">
                    <p>This is the <a href="#" className='kfTVTG'>modal content</a>. You can put any text or components here.</p>
                    <p className="mt-4">Click outside the modal or the close button to dismiss.</p>
                </section>
            </div>
        </div>
    </span>
  );
};

export default Modal;