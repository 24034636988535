import React, { useEffect, useState, Suspense } from 'react';
import Headlines from './Headlines';
import Sizzle from './Sizzle';

const NavPublic = React.lazy(() => import('./NavPublic'));

function Homepage() {
  // const [backendData, setBackendData] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const isDevelopment = process.env.NODE_ENV === 'development';
  //     const apiUrl = isDevelopment
  //       ? `http://localhost:5555/api/users`
  //       : `/api/users`;
    
  //     console.log('Current environment:', process.env.NODE_ENV);
  //     console.log('Fetching data from API URL:', apiUrl);
      
  //     try {
  //       const response = await fetch(apiUrl);
  //       console.log('Response status:', response.status);
        
  //       if (!response.ok) {
  //         throw new Error(`HTTP error! status: ${response.status}`);
  //       }
        
  //       const data = await response.json();
  //       console.log('Received data:', data);
        
  //       setBackendData(data);
  //       setIsLoading(false);
  //     } catch (error) {
  //       console.error('Fetch error:', error);
  //       setError(error.message);
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;
  // if (!backendData) return <div>No data received</div>;

  return (
    <>
    {/* <div className="">
      {backendData.users && backendData.users.length > 0 ? (
        <ul>
          {backendData.users.map((user, i) => (
            <li key={i}>{user}</li>
          ))}
        </ul>
      ) : (
        <p>No users found.</p>
      )}
    </div>   */}   
    <section className="section m-0 p-0" data-theme="dark">
      <div className="m-0 p-0">
        <div className="fullHeight is-flex is-flex-direction-column">
          <div className='p-5 m-0'>
            <Sizzle />
            <Suspense fallback={<div>Loading...</div>}>
                <NavPublic colorView="dark" />
            </Suspense>
          </div>
          <div className='container'>
            <div className='m-0 p-0'>
              <Headlines /> 
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Homepage;