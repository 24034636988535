import React, { useState } from 'react';

function ClientCard({ client }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className="square-box"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? (
        <>
          <div className='backgroundColorBlack4 border1 is-flex is-flex-direction-column is-align-items-center is-justify-content-space-between cursor'>
            <div className='pl-3 py-2 pr-2 oneHundredPercentWidth is-flex is-flex-direction-row is-justify-content-start is-align-items-center'>
              <p className="is-size-6 colorBlack2">
                {client.Year}
              </p>
            </div>
            <div className='oneHundredPercentWidth is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-flex-wrap-wrap'>
              <p className="is-size-4 has-text-weight-bold colorBlack2 has-text-centered">
                {client.Brand}
              </p>
            </div>
            <div className='pr-3 py-2 pl-2 oneHundredPercentWidth is-flex is-flex-direction-row is-justify-content-end is-align-items-center'>
              <p className="is-size-6 colorBlack2">
                {client.Industry}
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className='backgroundColorPink3 border1 cursor'>
          <p className="is-size-4 has-text-weight-bold colorBlack2 has-text-centered">
            {client.Client}
          </p>
        </div>
      )}
    </div>
  );
}

export default ClientCard;